<template>
    <v-container fluid tag="section">

        <div class="text-end">
            <v-btn outlined class="font-weight-bold me-2" :to="`/users`">{{ $t('Cancel') }}</v-btn>
            <v-btn color="purple" class="font-weight-bold me-0" @click="onSubmit" :loading="loading" :disabled="loading">{{ $t('Save') }}</v-btn>
        </div>

        <v-row>
            <v-col cols="12">
                <v-card class="rounded-lg mt-2">
                    <v-card-text class="px-0 py-0">
                        <v-form>
                            <v-container>
                                <v-text-field v-model="item.username" :label="$t('Username')"></v-text-field>
                                <v-text-field v-model="item.email" :label="$t('Email')"></v-text-field>
                                <v-text-field v-model="item.password" :label="$t('Password')"></v-text-field>
                                <v-select v-model="item.company_id" :label="$t('Company')" :items="companies"
                                    item-text="name" item-value="id" @change="onChangeCompany"></v-select>
                                <v-select multiple v-model="item.locations" :label="$t('Locations')" :items="locations"
                                    item-text="name" item-value="id" chips></v-select>
                                <v-select v-model="item.role_id" :label="$t('Roles')" :items="roles"
                                    item-text="name" item-value="id" chips></v-select>
                                <v-select v-model="item.lang" :label="$t('Language')" :items="languages"
                                    item-text="name" item-value="alias" chips></v-select>    
                                <v-checkbox v-model="item.push_notification " :label="$t('Push Notification')"></v-checkbox>
                                <v-checkbox v-model="item.email_notification " :label="$t('Email Notification')"></v-checkbox>
                                <v-select v-model="item.account_status" :label="$t('Status')"
                                    :items="account_status_items"></v-select>
                            </v-container>
                        </v-form>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

    </v-container>
</template>

<script>
import userService from '@/services/userService'
import companyService from '@/services/companyService'
import roleService from '@/services/roleService'

export default {
    data() {
        return {
            loading: false,
            item: {
                company_id: null,
                locations: [],
                role_id: null,
                name: null,
                email: null,
                lang: null,
                push_notification: false,
                email_notification : false,
                account_status: null,
            },
            companies: [],
            roles: [],
            languages: [
                {alias: 'en', name: this.$t('English')},
                {alias: 'de', name: this.$t('German')},
                {alias: 'fr', name: this.$t('French')},
                {alias: 'es', name: this.$t('Spanish')},
                {alias: 'it', name: this.$t('Italian')},
                {alias: 'nl', name: this.$t('Dutch')},
                {alias: 'tr', name: this.$t('Turkish')},
                {alias: 'ar', name: this.$t('Arabic')},
                {alias: 'hi', name: this.$t('Hindi')},
            ],
            account_status_items: ['pending', 'active', 'inactive']
        }
    },
    async created() {
        if (this.$route.params.id) {
            await this.getUser(this.$route.params.id)
        }
        this.getCompanies()
        this.getRoles()
    },
    computed: {
        locations() {
            return this.item.company_id ? this.companies.find(i => i.id == this.item.company_id)?.locations : []
        }
    },
    methods: {
        async getUser(id) {
            try {
                const result = await userService.get(id)
                this.item = result.data
                this.item.locations = this.item.locations.map(l => l.id)
            } catch (error) {
                this.$dialog.message.error(error.message)
                this.$router.push('/users')
            }
        },
        async getCompanies() {
            try {
                const result = await companyService.getAll({ include_locations: true })
                this.companies = result.data
            } catch (error) {
                this.$dialog.message.error(error.message)
            }
        },
        async getRoles() {
            try {
                const result = await roleService.getAll()
                this.roles = result.data
            } catch (error) {
                this.$dialog.message.error(error.message)
            }
        },
        onChangeCompany() {
            this.item.locations = []
        },
        async onSubmit() {
            this.loading = true
            try {
                if (this.$route.params.id) {
                    await userService.edit(this.$route.params.id, this.item)
                } else {
                    await userService.add(this.item)
                }
                this.$dialog.message.success(this.$t('Success'))
                this.$router.push('/users')
            } catch (error) {
                this.$dialog.message.error(error.message)
            }
            this.loading = false
        }
    },
}
</script>